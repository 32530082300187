.create-question-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h2,
h3 {
  text-align: center;
}

.form-group {
  margin-bottom: 15px;
}

label {
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
}

.question-type-select,
.question-textarea,
.option-input,
.correct-answer-input,
.answer-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 10px;
}

.add-option-btn,
.add-question-btn,
.grade-exam-btn {
  display: inline-block;
  padding: 8px 12px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.add-option-btn:hover,
.add-question-btn:hover,
.grade-exam-btn:hover {
  background-color: #0056b3;
}

.questions-list {
  list-style-type: none;
  padding-left: 0;
}

.question-preview {
  background: #f1f1f1;
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
}

.question-preview strong {
  display: block;
  margin-bottom: 5px;
}

* {
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
}

.exam-container {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h2 {
  text-align: center;
  color: #333;
}

.question-container {
  margin-bottom: 20px;
  padding: 15px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.question p {
  font-weight: 600;
  color: #555;
  margin-bottom: 10px;
}

.option {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  color: #333;
  cursor: pointer;
}

.text-input,
.textarea {
  width: 100%;
  padding: 10px;
  margin-top: 8px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.textarea {
  height: 100px;
}

.match-term {
  font-weight: 600;
  margin-right: 10px;
}

.submit-btn {
  width: 100%;
  padding: 12px;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  background-color: #4caf50;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-btn:hover {
  background-color: #45a049;
}

@media (max-width: 600px) {
  .exam-container {
    padding: 10px;
  }

  .text-input,
  .textarea {
    font-size: 14px;
  }

  .submit-btn {
    font-size: 16px;
    padding: 10px;
  }
}